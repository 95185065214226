import React from 'react';
import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import IconButton from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TodayIcon from '@material-ui/icons/Today';
import Box from '@material-ui/core/Box';
import duolingo from '../images/duolingo.svg'
import youtube from '../images/youtube.svg';
const DUOLINGO_CALENDAR = '0';
const YOUTUBE_CALENDAR = '1';

function getMonthString(m){
  var month = [];
  month[0] = "Janeiro";
  month[1] = "Fevereiro";
  month[2] = "Março";
  month[3] = "Abril";
  month[4] = "Maio";
  month[5] = "Junho";
  month[6] = "Julho";
  month[7] = "Agosto";
  month[8] = "Setembro";
  month[9] = "Outubro";
  month[10] = "Novembro";
  month[11] = "Dezembro";
  return month[m]
}

function getTimeTemplate(schedule) {
  var html = [];

  if (schedule.isPrivate) {
    html.push('<span class="calendar-font-icon ic-lock-b"></span>');
    html.push(' Private');
  } else {
    if (schedule.isReadOnly) {
      html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
    } else if (schedule.recurrenceRule) {
      html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
    } else if (schedule.attendees.length) {
      html.push('<span class="calendar-font-icon ic-user-b"></span>');
    } else if (schedule.location) {
      html.push('<span class="calendar-font-icon ic-location-b"></span>');
    }
    console.log(schedule)
    if(schedule.calendarId === DUOLINGO_CALENDAR){
      html.push('<img width="15px" src="'+duolingo+'" /> ' + schedule.title);
    }
    else if (schedule.calendarId === YOUTUBE_CALENDAR){
      html.push('<img width="15px" src="'+youtube+'" /> ' + schedule.title);      
    }
  }

  return html.join('');
}

// const myTheme = {
//     // Theme object to extends default dark theme.
//   };
const today = new Date()

const calendars = [
  {
    id: DUOLINGO_CALENDAR,
    name: 'Duolingo',
    color: '#ffffff',
    bgColor: '#9e5fff',
    dragBgColor: '#9e5fff',
    borderColor: '#9e5fff'
  },
  {
    id: YOUTUBE_CALENDAR,
    name: 'You tube',
    color: '#00a9ff',
    bgColor: '#00a9ff',
    dragBgColor: '#00a9ff',
    borderColor: '#00a9ff'
  }
];


const schedules = [
  {
    id: '0',
    calendarId: DUOLINGO_CALENDAR,
    title: 'Desafio',
    body: 'Fazer aula do duolingo',
    raw:'sd',
    category: 'time',
    dueDateClass: '',
    isReadOnly: true,
    start: today.toISOString(),
    end: today.toISOString(),
    customStyle: 'bbbbbbbbbb'
  },
  {
    id: '1',
    calendarId: YOUTUBE_CALENDAR,
    title:  'Vídeo Aula',
    body: 'Assistir vídeo de aula',
    category: 'time',
    dueDateClass: '',
    isReadOnly: true,
    start: today.toISOString(),
    end: today.toISOString(),
    customStyle: 'aaaaaaaa'
  },
];
class CalendarKaio extends React.Component {
  calendarRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {descricaoData: ''};  
  }
  componentDidMount(){
    this.setDescricaoData(new Date())
  }

  setDescricaoData = (date) => {
    this.setState({
      descricaoData: getMonthString(date.getMonth()) + "/" + date.getFullYear()
    })
  }

  handleClickNextButton = () => {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.next();
    this.setDescricaoData(calendarInstance.getDate())
  };

  handleClickPreviousButton = () => {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.prev();
    this.setDescricaoData(calendarInstance.getDate())
  };

  handleClickTodayButton = () => {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.today();
    this.setDescricaoData(calendarInstance.getDate())
  };
  
  render() {
    return (
      <React.Fragment>
        <Box mt='3vmin' mb='3vmin' fontSize="3vmin" fontWeight='bold'>
          Atividades do mês de <Box component='span' color='#3897f3'>{this.state.descricaoData}</Box>
        </Box>

        <IconButton color="primary" aria-label="Mês anterior"  onClick={this.handleClickTodayButton}>
          <TodayIcon />
        </IconButton>

        <IconButton color="primary" aria-label="Mês anterior"  onClick={this.handleClickPreviousButton}>
          <ArrowBackIosIcon />
        </IconButton>

        <IconButton color="primary" aria-label="Próximo mês"  onClick={this.handleClickNextButton}>
          <ArrowForwardIosIcon />
        </IconButton>


        <Calendar
          ref={this.calendarRef}

          view='month'
          scheduleView
          
          schedules={schedules}
          calendars={calendars}

          disableDblClick={true}
          disableClick={true}
          useDetailPopup
          template={{
            time: (schedule) => {return getTimeTemplate(schedule)},
            
            monthDayname: function(model) {
              return String(model.label).toLocaleUpperCase();
            },

          popupDetailLocation: function(schedule) {
              return 'Location : ' + schedule.location;
          },
          popupDetailUser: function(schedule) {
              return 'User : ' + (schedule.attendees || []).join(', ');
          },
          popupDetailState: function(schedule) {
              return 'State : ' + schedule.state || 'Busy';
          },
          popupDetailRepeat: function(schedule) {
              return 'Repeat : ' + schedule.recurrenceRule;
          },
          popupDetailBody: function(schedule) {
              return 'Descrição : ' + schedule.body;
          },
  
          }}
          
          month={{
            daynames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
          }}
        />
      </React.Fragment>
    )
  }
}

export default CalendarKaio;