import React from 'react';
import kaioLingo from './images/kaioLingo.png';
import premioAnual from './images/premioAnual.png';
import premioMensal from './images/premioMensal.png';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import Timer from 'react-compound-timer'
import CalendarKaio from './CalendarKaio';

const { innerWidth: width} = window;
const screenSize = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo:{
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%'
  },

  premioMensal:{
    backgroundSize: '32vmin',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${premioMensal})`,
    width: '40vmin',
    height: '40vmin',
  },
  premioAnual:{
    backgroundSize: '32vmin',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${premioAnual})`,
    width: '40vmin',
    height: '40vmin'
  },
  premioSM:{
    backgroundSize: '52vmin',
    width: '65vmin',
    height: '65vmin',
  },
  section:{
    minHeight:'50vmin',
  },

  sectionTop: {
  },
  nuvens: {
    margin: Math.floor(Math.random() * width/6) + width/12,
    textAlign: 'center',
    opacity: 0.2,
    color: '#92d1ea'
    },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function Nuvem(){
  const classes = useStyles();

  let divisor = 4;
  if (width > screenSize.sm){
    divisor = 3 * 5
  }

  const font_size = Math.floor(Math.random() * width/divisor) + width/(divisor*3);

  return (
    <FilterDramaIcon className={classes.nuvens} style={{ 
      fontSize: font_size, 
      margin: font_size/2
      // marginTop: Math.floor(Math.random() * width/3) + width/6 
    }} 
    />
  )
}
function NuvensList() {
  // const classes = useStyles();
  let nuvens = []

  let divisor = 3;
  if (width > screenSize.sm){
    divisor = 10 ;
  }

  for(let i=0; i<divisor; i++){
    nuvens.push(<Nuvem key={i}/>)
  }
  return (nuvens)
}

function CustomBackground() {
  const classes = useStyles();  
  return (
    <Box width='100%' className={classes.sectionBackground} style={{position: 'absolute', zIndex: -1, overflowY: 'hidden'}}  display="flex" alignItems="center" justifyContent="center">
      <Container style={{paddingTop:'5%', maxWidth:'100%'}}>
        <NuvensList />
      </Container>
    </Box>
  )
}
function CustomBackgroundCircle() {
  const classes = useStyles();  
  return (
    <Box height='100vmin' width='100vmin%' className={classes.sectionBackground} style={{position: 'absolute', zIndex: -1, overflowY: 'hidden'}}   display="flex"  justifyContent="center">
      <Box width='100vmin' height='100vmin' style={{backgroundColor: '#a4cef4', borderRadius: '50%', marginTop:'-75vmin'}}></Box>
    </Box>
  )
}

function CronometroAnual(){
  const now = new Date();
  
  //capturando os dias remanecentes do ano
  const start = new Date(2020, 5, 3);
  const diff = now - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const remain_days = 365 - Math.floor(diff / oneDay);

  const remain_days_milliseconds =  remain_days * 24 * 60 * 60 * 1000;
  const remain_today_milliseconds = (24 - now.getHours()) * 60 * 60 * 1000 + (60 - now.getMinutes()) * 60 * 1000 + (60 - now.getSeconds()) * 1000;
  const remain_milliseconds = remain_days_milliseconds + remain_today_milliseconds;

  return (
    <Timer
      initialTime={remain_milliseconds}
      direction="backward"
    >
      <Timer.Days />:
      <Timer.Hours />:
      <Timer.Minutes />:
      <Timer.Seconds />
    </Timer>
  );
}

function CronometroMensal(){
  const now = new Date();
  const day_count = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
  const remain_days =  day_count - now.getDate();
  const remain_days_milliseconds =  remain_days * 24 * 60 * 60 * 1000;
  const remain_today_milliseconds = (24 - now.getHours()) * 60 * 60 * 1000 + (60 - now.getMinutes()) * 60 * 1000 + (60 - now.getSeconds()) * 1000;
  const remain_milliseconds = remain_days_milliseconds + remain_today_milliseconds;

  return (
    <Timer
      initialTime={remain_milliseconds}
      direction="backward"
    >
      <Timer.Days />:
      <Timer.Hours />:
      <Timer.Minutes />:
      <Timer.Seconds />
    </Timer>
  );
}

function PremioMensalDados(){
  return (
    <Typography component="div">
      <Box  mt='10vmin' fontWeight="fontWeightMedium" fontSize="4vmin" letterSpacing={5}>
        Mensal
      </Box>
      <Box mt='3vmin' fontSize="3vmin" letterSpacing={5}>
        Faltam
      </Box>
      <Box mt='0vmin' fontSize="1.5vmin" letterSpacing={5}>
        <small> DIAS:HORAS:MIN:SEG </small>
      </Box>
      <Box m='0vmin' fontWeight="fontWeightMedium" fontSize="4vmin" letterSpacing={5}>
        <CronometroMensal />
      </Box>
    </Typography>
  )
}

function PremioAnualDados(){
  return (
    <Typography component="div">
      <Box  mt='10vmin' fontWeight="fontWeightMedium" fontSize="4vmin" letterSpacing={5}>
        Anual
      </Box>
      <Box mt='3vmin' fontSize="3vmin" letterSpacing={5}>
        Faltam
      </Box>
      <Box mt='0vmin' fontSize="1.5vmin" letterSpacing={5}>
        <small> DIAS:HORAS:MIN:SEG </small>
      </Box>
      <Box m='0vmin' fontWeight="fontWeightMedium" fontSize="4vmin" letterSpacing={5}>
        <CronometroAnual />
      </Box>
    </Typography>
  )
}



function App() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      
      <Box component="div" m={0} className={classes.section} display='flex' justifyContent="center">
        <CustomBackground />
        <CustomBackgroundCircle />
        <Container maxWidth="md" style={{zIndex: 1}}>
          <Grid container spacing={0} margin={0}>
            <Grid item xs={12}>
              <img alt='Kaio Lingo' src={kaioLingo} className={classes.logo}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      <Box m={0} className={classes.section} style={{backgroundColor:'#a4cef4'}} display={{xs:"none",  md:"flex" }} alignItems="center" justifyContent="center">
          <CustomBackground />            
          <Box  height='80vmin' width='80vmin' style={{backgroundColor: 'white', borderRadius: '50%'}} display="flex" alignItems="center" justifyContent="center" textAlign="center">
            <Box className={classes.premioMensal} display="flex" alignItems="center" justifyContent="center" color="primary.contrastText">
              <PremioMensalDados />
            </Box>
            <Box className={classes.premioAnual} display="flex" alignItems="center" justifyContent="center" color="primary.contrastText">
              <PremioAnualDados />
            </Box>
          </Box>
      </Box>

      <Box m={0} className={classes.section} style={{backgroundColor:'#a4cef4'}} display={{xs:"flex",  md:"none" }} alignItems="center" justifyContent="center" flexWrap="wrap">
          <CustomBackground />            
          <Box m='2vmin' height='70vmin' width='70vmin' style={{backgroundColor: 'white', borderRadius: '50%'}} display="flex" alignItems="center" justifyContent="center" textAlign="center">
            <Box className={`${classes.premioMensal} ${classes.premioSM}`} display="flex" alignItems="center" justifyContent="center" color="primary.contrastText">
              <PremioMensalDados />
            </Box>          
          </Box>
          <Box m='2vmin' height='70vmin' width='70vmin' style={{backgroundColor: 'white', borderRadius: '50%'}} display="flex" alignItems="center" justifyContent="center" textAlign="center">
            <Box className={`${classes.premioAnual} ${classes.premioSM}`} display="flex" alignItems="center" justifyContent="center" color="primary.contrastText">
              <PremioAnualDados />
            </Box>
          </Box>
      </Box>
      
      <Box component="div" m={0} className={classes.section}>
        <Container maxWidth="md" style={{zIndex: 1}}>
          <CalendarKaio />
        </Container>
      </Box>
      


    </React.Fragment>

  );
}

export default App;
